import React from 'react'
import { Grid } from '@mui/material'
import gg from './gg.gif'
import anos from './nug.gif'
import mr1 from './mr1.gif'
import mr2 from './mr2.gif'
import log from './dexos.png'
import logs from './nug.png'
import dino1 from './dino1z.jpg'
import dino2 from './dino2z.jpg'
import dino3 from './dino3.jpg'
import dino4 from './dino4.jpg'
import dino5 from './dino5z.jpg'



import Marquee from "react-fast-marquee";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import noot1 from './nan.jpeg'
function Desktop() {
  return (
    <div className='container'>
      <br/>
          <Grid container>
          <Grid item md={6} xs={12} lg={6} className='centerall'>
       
       <a href="https://t.me/DinoNuggieSolana" 
       style={{textDecoration:"none"}}
       
       ><FaTelegramPlane className='socials' />
  </a>&nbsp;&nbsp;
    
          
          <a href="https://twitter.com/DINOnuggiesSOL" 
       style={{textDecoration:"none"}}
       
       >  <FaXTwitter className='socials' />  </a>&nbsp;&nbsp;
   <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2ghiARdZduhyfmXKafBz6VGge8iumg8BriT2xTVLxxNR&fixed=in"
       style={{textDecoration:"none"}}
       
       > <img src={logs} style={{width:"40px"}} /> </a>&nbsp;&nbsp;
  <a href="https://dexscreener.com/solana/5a168tumat4i3rzbk1wedjkjm65osedtpgz2q6vuuns3"
       style={{textDecoration:"none"}}
       
       > <img src={log} style={{width:"45px"}} /> </a>


      </Grid>
      <Grid item md={6} xs={12} lg={6} className='centerall'>
   
   </Grid>
   <br/>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <h1 class="opacity-bg text-4xl md:text-5xl mb-4 text-center anos_textdglogo">
Dino Nuggies
          
          
          </h1>
   
      </Grid>
        
          </Grid>
          <br/>  

          <Grid container>
          
         <Grid item md={6} xs={12} lg={6} className='centerall'>
         
         <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textdg">

 <span className='spann'>$Dino</span> powerd by Solana
          
          
          </h1>
          <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2ghiARdZduhyfmXKafBz6VGge8iumg8BriT2xTVLxxNR&fixed=in'>          <button class="comic-button">Buy Dino</button></a>


         </Grid>

         <Grid item md={6} xs={12} lg={6} className='centerall'>
                <img src={anos} className='image_anos'/>
         </Grid>
          </Grid>

        


          <Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <Marquee className='backofit'>
          <img src={mr2} style={{width:"60px"}}/>&nbsp;&nbsp; $DINO Nuggies are my spirit Animals &nbsp;&nbsp; <img src={mr1} style={{width:"60px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </Marquee>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <p className='anon_p'> </p>
         </Grid>

       
          </Grid>
         
          <Grid item md={12} xs={12} lg={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20"><div class="flex justify-center">
  <div class="card"><div class="boarding">
    </div><div class="card-content"><h2 class="matrix-text card-title py-2 tokcolo">
    <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textdg">

<span className='spann'>$Dino</span> Token Address
         
         
         </h1>
      </h2></div>
      
      <Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
           
       <p style={{color:"#000" }} className='fontcam'>2ghiARdZduhyfmXKafBz6VGge8iumg8BriT2xTVLxxNR </p>
   
       <br/>
       <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2ghiARdZduhyfmXKafBz6VGge8iumg8BriT2xTVLxxNR&fixed=in'> <button class="comic-button"> Dino here</button></a>
        
         </Grid>
      
          </Grid> <br/>

         
      </div></div>
      
      
      </div>
      
</Grid>
<Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <Marquee className='backofit'>
          <img src={mr2} style={{width:"60px"}}/>&nbsp;&nbsp; $DINO Nuggies are my spirit Animals &nbsp;&nbsp; <img src={mr1} style={{width:"60px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </Marquee>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <p className='anon_p'> </p>
         </Grid>

       
          </Grid> 
     
<br/>
<Grid item md={12} xs={12} lg={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20"><div class="flex justify-center">
  <div class="card"><div class="boarding">
    </div><div class="card-content"><h2 class="matrix-text card-title py-2 tokcolo">
    <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textdg">

 <span className='spann'>$Dino</span> meme
         
         
         </h1>
      </h2></div>
      <div className='container'>

      <Grid container spacing={2}>
           <Grid item md={4} xs={12} lg={4} className='centerall'>
           
        <img src={dino1} style={{width:"100%"}}/>
           </Grid>
           <Grid item md={4} xs={12} lg={4} className='centerall'>
           
           <img src={dino2} style={{width:"100%"}}/>
              </Grid>
              <Grid item md={4} xs={12} lg={4} className='centerall'>
           
        <img src={dino3} style={{width:"100%"}}/>
           </Grid>
           <Grid item md={6} xs={12} lg={6} className='centerall'>
           
           <img src={dino4} style={{width:"100%"}}/>
              </Grid>
              <Grid item md={6} xs={12} lg={6} className='centerall'>
           
           <img src={dino5} style={{width:"100%"}}/>
              </Grid>
          </Grid> 

      </div>
     
          
          
          <br/>

         
      </div></div>
      
      
      </div>
      
</Grid>
<Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <Marquee className='backofit'>
          <img src={mr2} style={{width:"60px"}}/>&nbsp;&nbsp; $DINO Nuggies are my spirit Animals &nbsp;&nbsp; <img src={mr1} style={{width:"60px"}}/>
  </Marquee>
         </Grid>
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <p className='anon_p'> </p>
         </Grid>

       
          </Grid>   
     

          <Grid item md={12} xs={12} lg={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20"><div class="flex justify-center">
  <div class="card"><div class="boarding">
    </div><div class="card-content"><h2 class="matrix-text card-title py-2 tokcolo">
    <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textdg">

 Socials <br/>
Join the <span className='spann'>$Dino</span> community
         
         
         </h1>
      </h2></div>
      
      <Grid container>
          
         <Grid item md={12} xs={12} lg={12} className='centerall'>
           
          <a href='https://t.me/DinoNuggieSolana'>   <button class="comic-button">
          <FaTelegramPlane className='socials' /></button></a>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='https://twitter.com/DINOnuggiesSOL'>
          <button class="comic-button">
          <FaXTwitter className='socials' /> </button>

          </a>
         
       <br/>       <br/>       <br/>
       <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2ghiARdZduhyfmXKafBz6VGge8iumg8BriT2xTVLxxNR&fixed=in'>          <button class="comic-button" style={{fontSize:"14px"}}>
          Eat Nuggies</button></a>

                </Grid>
                <Grid item md={3} xs={12} lg={3} className='centerall'>
           
          
          
       
               
                </Grid>
                <Grid item md={6} xs={12} lg={6} className='centerall'>
           
           <img src={gg} style={{width:"100%"}}/>
          
       
               
                </Grid>
                <Grid item md={3} xs={12} lg={3} className='centerall'>
           
          
          
       
               
           </Grid>
          </Grid> <br/>

         
      </div></div>
      
      
      </div>
      
</Grid>


          






<br/>  <br/>
<br/>  <br/>
    </div>
  )
}

export default Desktop